import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import FormRadioButton from '../../base/form/FormRadioButton';
import CreditCardInput from 'react-credit-card-input';
import { autorun } from 'mobx';
import Localized from '../../base/i18n/Localized';
import moment from 'moment-timezone';
import ReactLoading from 'react-loading';

moment.tz.setDefault('Asia/Dubai');

const Renew = inject('rootStore')(observer(
	class Renew extends Component {

		constructor(props) {
			super(props);

			this.renewRef = React.createRef();

			this.handleRenewClick = this.handleRenewClick.bind(this);
			
			this.scrollToTop = this.scrollToTop.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.renew.title');
			});
		}

		componentDidMount() {
			const { userStore } = this.props.rootStore;

			userStore.resetRegistrationFields();

			userStore.loadCountries().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of countries/nationalities, please reload the page and try again.');
				}
			});

			userStore.loadSubscriptions().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of subscriptions, please reload the page and try again.');
				} else {
					// Set default subscription
					userStore.setSubscription(String(userStore.subscriptions.find(s => s.subscriptionMonths === 12).id));
				}
			});

			userStore.loadLevels().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of levels, please reload the page and try again.');
				}
			});

			userStore.getLastTransactionDetails().then(details => {
				if (details) {
					userStore.setFirstName(details.billingFirstName);
					userStore.setLastName(details.billingLastName);
					userStore.setAddress(details.billingAddress);
					userStore.setCity(details.billingCity);
					userStore.setCountry(details.billingCountry);
					userStore.setPostalCode(details.billingPostalCode);
				}
			});
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handleSubscriptionChange = e => this.props.rootStore.userStore.setSubscription(e.target.value);
		handleFirstNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setFirstName(e.target.value);
			}
		}
		handleLastNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setLastName(e.target.value);
			}
		}
		handleAddressChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s/\\.]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setAddress(e.target.value);
			}
		}
		handlePostalCodeChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setPostalCode(e.target.value);
			}
		}
		handleCityChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setCity(e.target.value);
			}
		}
		handleCountryChange = e => this.props.rootStore.userStore.setCountry(e.target.value);
		handleCcNumberChange = e => this.props.rootStore.userStore.setCcNumber(e.target.value);
		handleCcExpiryChange = e => this.props.rootStore.userStore.setCcExpiry(e.target.value);
		handleCcCvvChange = e => this.props.rootStore.userStore.setCcCvv(e.target.value);

		handleRenewClick(e) {
			e.preventDefault();

			const { userStore }	= this.props.rootStore;

			userStore.renew();

			this.scrollToTop();
		}

		scrollToTop() {
			if (this.renewRef.current) {
				requestAnimationFrame(() => {
					this.renewRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				});
			}
		}

		render() {
			const { locale, translateMessage } = this.props.rootStore.commonStore;
			const { me, countries, subscriptions, levels, subscription, firstName, lastName, address, postalCode, city, country, ccNumber, ccExpiry, ccCvv, error, success, loading } = this.props.rootStore.userStore;
			//const freeRenew = me.companyCode ? !!me.companyCode.companyMembershipFree : false;
			const freeRenew = false;
			//const renewDisabled = !subscription || (!freeRenew && !(firstName && lastName && address && postalCode && city && country && ccNumber && ccExpiry && ccCvv));
			const renewDisabled = !subscription || !(firstName && lastName && address && postalCode && city && country && ccNumber && ccExpiry && ccCvv);
			const subscriptionInfo = subscriptions.find(s => String(s.id) === subscription);
			const currentLevel = levels.find(l => l.id === me.membershipLevel.id);
			const noRenew = me.membershipLevel.id === 4 || moment(me.subscriptionExpireDate).isAfter() || !!!me.companyCode.companyAllowRenew;

			return (
				<section ref={this.renewRef} className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.renew.title" />
					</h2>

					<div className="section__wrapper">
						<div className="renew">
							<div className="renew__form">
								<form className="form">
									{!!error ? (
									<div className="form__message form__message--error">{error}</div>
									) : null}

									{!!success ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.renew.subtitle.success" />
										</h4>

										<p className="form__text">
											<Localized code="page.renew.text.success" />
										</p>

										<p className="form__text">
											<Link to="/account" className="button button--gold">
												<Localized code="page.renew.link.account" />
											</Link>
										</p>
									</div>
									) : null}

									{!!noRenew ? (
										<div className="form__section">
											<h4 className="form__title">
												<Localized code="page.renew.subtitle.notNeeded" />
											</h4>

											<p className="form__text">
												<Localized code="page.renew.text.notNeeded" />
											</p>

											<p className="form__text">
												<Link to="/account" className="button button--gold">
													<Localized code="page.renew.link.account" />
												</Link>
											</p>
										</div>
									) : null}

									{!success && !noRenew ? (
									<div className="form__section">
										<p className="form__text form__text--center">
											<Localized code="page.renew.text.begin" />
										</p>

										{currentLevel && subscriptionInfo ? (
											<div className="renew__level">
												<div className="renew__level-name">
													<div className="renew__level-label">
														<Localized code="page.renew.text.membershipLevel" />
													</div>
													<div className="renew__level-value">
														{me.companyCode.companyInitiative ?
															(locale === 'ar' ? me.companyCode.companyArShortName : me.companyCode.companyEnShortName) :
															currentLevel.levelName}
													</div>
												</div>

												<div className="renew__level-price">
													<div className="renew__level-label">
														<Localized code="page.renew.text.monthlyPrice" />
													</div>
													<div className="renew__level-value">{freeRenew ? 0 : currentLevel.levelAmount} AED</div>
												</div>

												<div className="renew__total-price">
													<div className="renew__level-label">
														<Localized code="page.renew.text.totalPrice" />
													</div>
													<div className="renew__level-value">{(freeRenew ? 0 : currentLevel.levelAmount) * subscriptionInfo.subscriptionMonths} AED</div>
												</div>
											</div>
										) : null}

										<h4 className="form__title">
											<Localized code="page.signup.subtitle.subscription" />
										</h4>

										<div className="form__group">
											<label className="form__label">
												<Localized code="page.signup.label.subscriptionPeriod" />
											</label>
											<div className="form__radios form__radios--inline">
												{subscriptions.filter(s => s.subscriptionMonths === 12).map(s => (
													<FormRadioButton key={s.id} name="subscription" value={s.id} label={locale === 'ar' ? s.subscriptionNameAr : s.subscriptionNameEn} current={subscription} onChange={this.handleSubscriptionChange} />	
												))}
											</div>
										</div>

										{!freeRenew ? (
											<Fragment>
												<h4 className="form__title">
													<Localized code="page.signup.subtitle.billingDetails" />
												</h4>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="address">
																<Localized code="page.cart.label.address" />
															</label>
															<input className="form__input" type="text" id="address" value={address} onChange={this.handleAddressChange} />
														</div>
													</div>

													{false ? (
														<div className="form__column form__column--quarter">
															<div className="form__group">
																<label className="form__label" htmlFor="postalCode">
																	<Localized code="page.cart.label.postalCode" />
																</label>
																<input className="form__input" type="text" id="postalCode" value={postalCode} onChange={this.handlePostalCodeChange} />
															</div>
														</div>
													) : null}
												</div>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="country">
																<Localized code="page.cart.label.country" />
															</label>
															<div className="form__select-wrap">
																<select className="form__select" id="country" value={country} onChange={this.handleCountryChange}>
																	<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
																	{countries.map(country => (
																		<option key={country.countryCode2c} value={country.countryCode2c}>{locale === 'ar' ? country.countryNameAr : country.countryName}</option>
																	))}
																</select>
															</div>
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="city">
																<Localized code="page.cart.label.city" />
															</label>
															<input className="form__input" type="text" id="city" value={city} onChange={this.handleCityChange} />
														</div>
													</div>
												</div>

												<h4 className="form__title">
													<Localized code="page.signup.subtitle.paymentDetails" />
												</h4>

												<div className="form__row">
													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="firstName">
																<Localized code="page.cart.label.firstName" />
															</label>
															<input className="form__input" type="text" id="firstName" value={firstName} onChange={this.handleFirstNameChange} />
														</div>
													</div>

													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="lastName">
																<Localized code="page.cart.label.lastName" />
															</label>
															<input className="form__input" type="text" id="lastName" value={lastName} onChange={this.handleLastNameChange} />
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.signup.label.ccDetails" />
															</label>
															<CreditCardInput
																containerClassName="form__cc-container"
																fieldClassName="form__cc-field"
																inputClassName="form__cc-input"
																dangerTextClassName="form__field-error"
																cardNumberInputProps={{ value: ccNumber, onChange: this.handleCcNumberChange }}
																cardExpiryInputProps={{ value: ccExpiry, onChange: this.handleCcExpiryChange }}
																cardCVCInputProps={{ value: ccCvv, onChange: this.handleCcCvvChange }}
															/>
														</div>
													</div>
												</div>
											</Fragment>
										) : null}

										<div className="form__buttons">
											<button disabled={renewDisabled || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleRenewClick}>
												{loading ? (
													<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
												) : null}
												<Localized code="page.renew.button.renew" />
											</button>
										</div>
									</div>
									) : null}
								</form>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Renew;
