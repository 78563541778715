import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import CreditCardInput from 'react-credit-card-input';
import { autorun } from 'mobx';
import Localized from '../../base/i18n/Localized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import FormError from '../../base/form/FormError';
import ReactLoading from 'react-loading';
import FormCheckboxButton from '../../base/form/FormChecboxButton';

import imgCardPreview from '../../../assets/img/card-preview.jpg';

const Upgrade = inject('rootStore')(observer(
	class Upgrade extends Component {

		constructor(props) {
			super(props);

			this.upgradeRef = React.createRef();
			this.cardPreviewRef = React.createRef();

			this.handleUpgradeClick = this.handleUpgradeClick.bind(this);

			this.levelClasses = this.levelClasses.bind(this);
			this.scrollToTop = this.scrollToTop.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.upgrade.title');
			});

			this.state = {
				cpFontSize: 22,
				cpTop: 120,
				cpLeft: 45
			};
		}

		componentDidMount() {
			const { userStore, contentStore } = this.props.rootStore;
			const { me: { membershipLevel, companyCode } } = userStore;

			userStore.resetRegistrationFields();
			userStore.setLevel('');

			// Set default selected level to be one higher than current level if possible
			if (membershipLevel.id > 1 && membershipLevel.id > companyCode.companyLevelId) {
				userStore.setLevel(membershipLevel.id - 1);
			}

			userStore.setCountry('AE');

			userStore.loadCountries().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of countries/nationalities, please reload the page and try again.');
				}
			});

			userStore.loadSubscriptions().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of subscriptions, please reload the page and try again.');
				} else {
					// Set default subscription
					userStore.setSubscription(String(userStore.subscriptions.find(s => s.subscriptionMonths === 12).id));
				}
			});

			userStore.loadLevels().then(result => {
				if (!result) {
					userStore.setError('Unable to get list of levels, please reload the page and try again.');
				}
			});

			contentStore.loadContent('levelFeaturesDiscount', 'levelFeaturesSilver', 'levelFeaturesGold', 'levelFeaturesPlatinum');

			userStore.getLastTransactionDetails().then(details => {
				if (details) {
					userStore.setFirstName(details.billingFirstName);
					userStore.setLastName(details.billingLastName);
					userStore.setAddress(details.billingAddress);
					userStore.setCity(details.billingCity);
					userStore.setCountry(details.billingCountry);
					userStore.setPostalCode(details.billingPostalCode);
				}
			});

			window.addEventListener('resize', this.handleResize);

			this.handleResize();
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();

			window.removeEventListener('resize', this.handleResize);
		}

		handleResize = () => {
			const baseWidth = 485;

			if (this.cardPreviewRef.current) {
				const cr = this.cardPreviewRef.current.getBoundingClientRect();

				const wChangePercent = cr.width * 100 / baseWidth;

				this.setState({
					cpFontSize: 22 * (wChangePercent / 100),
					cpTop: 120 * (wChangePercent / 100),
					cpLeft: 45 * (wChangePercent / 100)
				});
			}
		}

		handleNameFazaaChange = e => {
			if (e.target.validity.valid) {
				this.props.rootStore.userStore.setNameFazaa(e.target.value);
			}
		}
		handleLevelChange = id => this.props.rootStore.userStore.setLevel(id);
		handleFirstNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setFirstName(e.target.value);
			}
		}
		handleLastNameChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setLastName(e.target.value);
			}
		}
		handleAddressChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s/\\.]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setAddress(e.target.value);
			}
		}
		handlePostalCodeChange = e => {
			if (new RegExp('^[a-zA-Z\\d\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setPostalCode(e.target.value);
			}
		}
		handleCityChange = e => {
			if (new RegExp('^[a-zA-Z\\-\\s]*$').test(e.target.value)) {
				this.props.rootStore.userStore.setCity(e.target.value);
			}
		}
		handleCountryChange = e => this.props.rootStore.userStore.setCountry(e.target.value);
		handleCcNumberChange = e => this.props.rootStore.userStore.setCcNumber(e.target.value);
		handleCcExpiryChange = e => this.props.rootStore.userStore.setCcExpiry(e.target.value);
		handleCcCvvChange = e => this.props.rootStore.userStore.setCcCvv(e.target.value);
		handleTermsRefundsChange = e => this.props.rootStore.userStore.setTermsRefunds(e.target.checked);

		handleUpgradeClick(e) {
			e.preventDefault();

			const { commonStore, userStore } = this.props.rootStore;
			const { translateMessage } = commonStore;
			const { nameFazaa } = userStore;

			userStore.clearErrors();

			// Name on card has to contain at least one space character
			if (!nameFazaa.trim().includes(' ')) {
				userStore.addError('nameFazaa', translateMessage('generic.error.nameMustContainSpace'));
			}

			if (userStore.errors.length === 0) {
				userStore.upgrade();
			} else {
				userStore.setError(translateMessage('generic.error.checkFields'));
			}

			this.scrollToTop();
		}

		levelClasses(id) {
			const { level } = this.props.rootStore.userStore;

			if (!level) {
				return '';
			} else {
				if (level === id) {
					return 'signup__level--selected';
				} else {
					return 'signup__level--not-selected';
				}
			}
		}

		scrollToTop() {
			if (this.upgradeRef.current) {
				requestAnimationFrame(() => {
					this.upgradeRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				});
			}
		}

		render() {
			const { locale, translateMessage } = this.props.rootStore.commonStore;
			const { me, countries, nameFazaa, levels, subscriptions, subscription, level, firstName, lastName, address, postalCode, city, country, ccNumber, ccExpiry, ccCvv, termsRefunds, error, success, loading, errors } = this.props.rootStore.userStore;
			//const freeUpgrade = me.companyCode ? !!me.companyCode.companyMembershipFree : false;
			const freeUpgrade = false;
			//const upgradeDisabled = !nameFazaa || !level || (!freeUpgrade && !(firstName && lastName && address && postalCode && city && country && ccNumber && ccExpiry && ccCvv));
			const upgradeDisabled = !nameFazaa || !level || !(firstName && lastName && address && postalCode && city && country && ccNumber && ccExpiry && ccCvv && termsRefunds);
			const subscriptionInfo = subscriptions.find(s => String(s.id) === subscription);
			const currentLevel = levels.find(l => l.id === me.membershipLevel.id);
			const { contentStore } = this.props.rootStore;
			const content = {
				levelFeaturesDiscount: contentStore.getContent('levelFeaturesDiscount'),
				levelFeaturesSilver: contentStore.getContent('levelFeaturesSilver'),
				levelFeaturesGold: contentStore.getContent('levelFeaturesGold'),
				levelFeaturesPlatinum: contentStore.getContent('levelFeaturesPlatinum')
			};
			const noUpgrade = me.membershipLevel.levelName === 'Platinum' || !!!me.companyCode.companyAllowSignup || me.companyCode.companyLevelId >= me.membershipLevel.id || me.companyCode.companyInitiative;
			const { cpFontSize, cpTop, cpLeft } = this.state;

			return (
				<section ref={this.upgradeRef} className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.upgrade.title" />
					</h2>

					<div className="section__wrapper">
						<div className="upgrade">
							<div className="upgrade__form">
								<form className="form">
									{!!error ? (
									<div className="form__message form__message--error">{error}</div>
									) : null}

									{!!success ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.upgrade.subtitle.success" />
										</h4>

										<p className="form__text">
											<Localized code="page.upgrade.text.success" />
										</p>

										<p className="form__text">
											<Link to="/account" className="button button--gold">
												<Localized code="page.upgrade.link.account" />
											</Link>
										</p>
									</div>
									) : null}

									{!!noUpgrade ? (
									<div className="form__section">
										<h4 className="form__title">
											<Localized code="page.upgrade.subtitle.notPossible" />
										</h4>

										<p className="form__text">
											<Localized code="page.upgrade.text.notPossible" />
										</p>

										<p className="form__text">
											<Link to="/account" className="button button--gold">
												<Localized code="page.upgrade.link.account" />
											</Link>
										</p>
									</div>
									) : null}

									{!success && !noUpgrade ? (
									<div className="form__section">
										<p className="form__text form__text--center">
											<Localized code="page.upgrade.text.begin" />
										</p>

										<div className="form__row">
											<div className="form__column form__column--half">
												<div className="form__group">
													<label className="form__label" htmlFor="nameFazaa">
														<Localized code="page.signup.label.nameOnFazaaCard" />
													</label>
													<input pattern="[a-zA-Z ]+" maxLength="26" className="form__input" type="text" id="nameFazaa" value={nameFazaa} onChange={this.handleNameFazaaChange} />
													<FormError errors={errors} field="nameFazaa" />
												</div>

												{nameFazaa ? (
													<div ref={this.cardPreviewRef} className="card-preview">
														<img className="card-preview__image" src={imgCardPreview} />
														<span style={{ fontSize: cpFontSize + 'px', top: cpTop + 'px', left: cpLeft + 'px' }} className="card-preview__text">{nameFazaa}</span>
													</div>
												) : null}
											</div>
										</div>

										<div className="form__group">
											<label className="form__label">
												<Localized code="page.signup.label.cardLevel" />
											</label>
											<div className="signup__levels">
												{levels.filter(l => l.levelAmount > currentLevel.levelAmount && (l.id >= me.companyCode.companyLevelId)).map(l => (
													<div key={l.id} className={`signup__level signup__level--${l.levelName.split(' ')[0].toLowerCase()} ${this.levelClasses(l.id)}`} onClick={() => this.handleLevelChange(l.id)}>
														<h4 className="signup__level-title">
															<FontAwesomeIcon icon={faCheck} className="signup__level-icon" />
															{l.levelName.split(' ')[0]}
														</h4>

														{false ? (
															<p className="signup__level-price">
																<Localized code="page.signup.text.amountPerMonth" amount={freeUpgrade ? 0 : l.levelAmount} />
															</p>
														) : (
															<p className="signup__level-price">
																<Localized code="page.signup.text.amountPerYear" amount={freeUpgrade ? 0 : (l.levelAmount * 12)} />
															</p>
														)}

														{content['levelFeatures' + l.levelName.split(' ')[0]] ? (
														<div className="signup__level-description cms-generated" dangerouslySetInnerHTML={{__html: content['levelFeatures' + l.levelName.split(' ')[0]].localData[locale].contentValue}} />
														) : null}

														{subscriptionInfo ? (
														<Fragment>
															<p className="signup__level-total">
																<Localized code="page.signup.text.total" />
															</p>
															<p className="signup__level-total-amount">{(freeUpgrade ? 0 : l.levelAmount) * subscriptionInfo.subscriptionMonths} AED</p>
															<p className="signup__repeat">
																<Localized code="page.signup.text.fragment.every" period={locale === 'ar' ? subscriptionInfo.subscriptionNameAr : subscriptionInfo.subscriptionNameEn} />
															</p>
														</Fragment>
														) : null}
													</div>
												))}
											</div>
										</div>

										{!freeUpgrade ? (
											<Fragment>
												<h4 className="form__title">
													<Localized code="page.signup.subtitle.billingDetails" />
												</h4>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="address">
																<Localized code="page.cart.label.address" />
															</label>
															<input className="form__input" type="text" id="address" value={address} onChange={this.handleAddressChange} />
														</div>
													</div>

													{false ? (
														<div className="form__column form__column--quarter">
															<div className="form__group">
																<label className="form__label" htmlFor="postalCode">
																	<Localized code="page.cart.label.postalCode" />
																</label>
																<input className="form__input" type="text" id="postalCode" value={postalCode} onChange={this.handlePostalCodeChange} />
															</div>
														</div>
													) : null}
												</div>

												<div className="form__row">
													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="country">
																<Localized code="page.cart.label.country" />
															</label>
															<div className="form__select-wrap">
																<select className="form__select" id="country" value={country} onChange={this.handleCountryChange}>
																	<option value="">{translateMessage('generic.label.pleaseSelect')}</option>
																	{countries.map(country => (
																		<option key={country.countryCode2c} value={country.countryCode2c}>{locale === 'ar' ? country.countryNameAr : country.countryName}</option>
																	))}
																</select>
															</div>
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label" htmlFor="city">
																<Localized code="page.cart.label.city" />
															</label>
															<input className="form__input" type="text" id="city" value={city} onChange={this.handleCityChange} />
														</div>
													</div>
												</div>

												<h4 className="form__title">
													<Localized code="page.signup.subtitle.paymentDetails" />
												</h4>

												<div className="form__row">
													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="firstName">
																<Localized code="page.cart.label.firstName" />
															</label>
															<input className="form__input" type="text" id="firstName" value={firstName} onChange={this.handleFirstNameChange} />
														</div>
													</div>

													<div className="form__column form__column--quarter">
														<div className="form__group">
															<label className="form__label" htmlFor="lastName">
																<Localized code="page.cart.label.lastName" />
															</label>
															<input className="form__input" type="text" id="lastName" value={lastName} onChange={this.handleLastNameChange} />
														</div>
													</div>

													<div className="form__column">
														<div className="form__group">
															<label className="form__label">
																<Localized code="page.signup.label.ccDetails" />
															</label>
															<CreditCardInput
																containerClassName="form__cc-container"
																fieldClassName="form__cc-field"
																inputClassName="form__cc-input"
																dangerTextClassName="form__field-error"
																cardNumberInputProps={{ value: ccNumber, onChange: this.handleCcNumberChange }}
																cardExpiryInputProps={{ value: ccExpiry, onChange: this.handleCcExpiryChange }}
																cardCVCInputProps={{ value: ccCvv, onChange: this.handleCcCvvChange }}
															/>
														</div>
													</div>
												</div>

												<div className="form__group">
													<div className="form__checkboxes">
														<FormCheckboxButton name="termsRefunds" value="1" label={translateMessage('page.signup.label.acceptNoRefunds', {
															__links: {
																link1: <Link className="form__label-link" to="/page/tnc" target="_blank" />
															}
														})} checked={termsRefunds} onChange={this.handleTermsRefundsChange} />
													</div>
												</div>
											</Fragment>
										) : null}

										<div className="form__buttons">
											<button disabled={upgradeDisabled || loading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleUpgradeClick}>
												{loading ? (
													<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
												) : null}
												<Localized code="page.upgrade.button.upgrade" />
											</button>
										</div>
									</div>
									) : null}
								</form>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Upgrade;
