import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Localized from '../../base/i18n/Localized';
import { autorun } from 'mobx';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUser } from '@fortawesome/free-solid-svg-icons';
import Config from '../../../config/Config';
import Qs from 'qs';
import Loader from 'react-loaders';

import imgUaePassSignInButton from '../../../assets/img/UAEPASS_Sign_in_Btn_Outline_Pill_Active.png';
import imgUaePassSignInButtonAr from '../../../assets/img/AR_UAEPASS_Sign_in_Btn_Outline_Pill_Active.png';

const SignIn = inject('rootStore')(observer(
	class SignIn extends Component {

		constructor(props) {
			super(props);

			this.state = {
				loading: false,
				showPassword: false,
				authorized: false,
				invalidState: undefined,
				uaePassResponse: undefined,

				manualLinkingUIVisible: false,
				manualLinkingUIQuestionVisible: false,
				manualLinkingUINoAccountVisible: false,
				manualLinkingUILinkAccountVisible: false,

				// TODO Do we need this?
				linked: false,
			};

			this.handleSignInClick = this.handleSignInClick.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.signIn.title');
			});
		}

		componentDidMount() {
			const { userStore, commonStore } = this.props.rootStore;
			const { translateMessage } = commonStore;

			if (userStore.authenticated) {
				this.props.history.replace('/');
			}

			const uaePassFlowDataJSON = localStorage.getItem('uaePassFlowData');
			let lastError = undefined;
			
			if (uaePassFlowDataJSON) {
				const uaePassFlowData = JSON.parse(uaePassFlowDataJSON);

				if (uaePassFlowData.lastError) {
					lastError = uaePassFlowData.lastError;

					localStorage.setItem('uaePassFlowData', JSON.stringify({
						...uaePassFlowData,
						lastError: undefined
					}));
				}
			}

			userStore.setUaePassError(lastError || false);
			userStore.setPrincipal('');
			userStore.setSecret('');

			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			if (params.error) {
				if ((params.error_description && params.error_description.toLowerCase().includes('cancelled')) ||
					(params.error && params.error.toLowerCase().includes('cancelled'))) {
					userStore.setUaePassError(translateMessage('page.uaePass.error.authCancelled'));
				} else {
					userStore.setUaePassError(translateMessage('page.uaePass.error.generalError'));
				}
			}

			if (params.code && params.state) {
				this.setState({
					loading: true,
					invalidState: params.state !== localStorage.getItem('uaePassState'),
					authorized: !params.error,
					uaePassResponse: params
				}, () => {
					if (params.code && !this.state.invalidState) {
						// If this fails manual UAE PASS linking flow needs to happen
						userStore.initiateUAEPassLogin(params.code).then(async result => {
							localStorage.removeItem('uaePassState');
							
							if (result) {
								// At this point user is already logged in so we can call self
								if (await userStore.completeUAEPassLogin()) {
									this.completeAfterSignInActions();
								}

								// Mark the user as signed in with UAE PASS
								localStorage.setItem('uaePassFlowData', JSON.stringify({
									status: true
								}));
							} else {
								// Handle manual linking if user has an account with Fazaa
								// TODO Additional checks needed before showing manual linking UI !!!
								if (userStore.uaePassManualLinkEligible) {
									this.setState({
										loading: false,
										manualLinkingUIVisible: true,
										manualLinkingUIQuestionVisible: true,
									});
								} else {
									this.setState({
										loading: false,
									});
								}
							}
						});
					} else {
						this.setState({
							loading: false,
						});
					}
				});
			}
		}

		componentWillUnmount() {
			const { userStore } = this.props.rootStore;

			this.mobxAutorunDisposer();

			userStore.setUaePassFlowInProgress(false);
		}

		handlePrincipalChange = e => this.props.rootStore.userStore.setPrincipal(e.target.value);
		handleSecretChange = e => this.props.rootStore.userStore.setSecret(e.target.value);

		async handleSignInClick(e, linkWithUaePass = false) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			if (await userStore.signIn(linkWithUaePass)) {
				if (linkWithUaePass) {
					userStore.initiateUAEPassLinking(this.state.uaePassResponse.code).then(result => {
						localStorage.removeItem('uaePassState');

						if (result) {
							localStorage.setItem('uaePassFlowData', JSON.stringify({
								status: true
							}));

							userStore.setAuthenticated(true);
							
							this.completeAfterSignInActions();
						} else {
							// Reset data and allow user to try again from begining
							userStore.signOut(false, true);
						}
					});
				} else {
					localStorage.setItem('uaePassFlowData', JSON.stringify({
						status: false
					}));

					this.completeAfterSignInActions();
				}
			}
		}

		handlePasswordShowToggle = () => {
			this.setState({
				showPassword: !this.state.showPassword
			});
		};

		handleUAEPassLogin = () => {
			const { commonStore, userStore } = this.props.rootStore;
			const { locale } = commonStore;

			const redirectUrl = new URL(Config.UAE_PASS_ENDPOINT_AUTH);

			redirectUrl.searchParams.set('redirect_uri', Config.UAE_PASS_CALLBACK_URL);
			redirectUrl.searchParams.set('client_id', Config.UAE_PASS_CLIENT_ID);
			redirectUrl.searchParams.set('response_type', 'code');
			// Recommended
			redirectUrl.searchParams.set('state', userStore.generateUAEPassState());
			redirectUrl.searchParams.set('scope', 'urn:uae:digitalid:profile:general');
			// Optional
			redirectUrl.searchParams.set('acr_values', 'urn:safelayer:tws:policies:authentication:level:low');
			redirectUrl.searchParams.set('ui_locales', locale);

			window.location = redirectUrl.toString();
		};

		handleUAEPASSSelectUser = id => {
			const { userStore } = this.props.rootStore;

			userStore.switchToLinkedMemberContext(id).then(async () => {
				if (await userStore.completeUAEPassLogin()) {
					this.completeAfterSignInActions();
				}
			});
		};

		completeAfterSignInActions = async () => {
			const { userStore } = this.props.rootStore;
			const { redirectTo, redirectUrl, redirectRequiresProfile, isProfileComplete } = userStore;

			if (redirectUrl && (!redirectRequiresProfile || (redirectRequiresProfile && isProfileComplete))) {
				const token = await userStore.ssoTokenRH();

				if (token) {
					window.location = redirectUrl + token;

					return;
				} else {
					userStore.setRedirectUrl('');
					userStore.setRedirectRequiresProfile(false);
				}
			}

			if (redirectTo) {
				this.props.history.push(redirectTo);

				userStore.setRedirectTo('');
			} else {
				this.props.history.push('/');
			}
		};

		handleProceedWithCurrent = () => {
			this.completeAfterSignInActions();
		};

		handleUAEPASSCancel = () => {
			const { userStore } = this.props.rootStore;

			userStore.signOut().then(() => {
				this.props.history.push('/');
			});
		};

		handleManualLinkingHasAccountClick = () => {
			const { userStore } = this.props.rootStore;

			userStore.setUaePassError('');
			userStore.setError('');

			this.setState({
				manualLinkingUIQuestionVisible: false,
				manualLinkingUILinkAccountVisible: true
			});
		};

		handleManualLinkingNoAccountClick = () => {
			this.setState({
				manualLinkingUIQuestionVisible: false,
				manualLinkingUINoAccountVisible: true
			});
		};

		handleManualLinkingCloseClick = () => {
			const { userStore } = this.props.rootStore;

			// Reset any errors that may have occured during UAE PASS sign in attempt
			userStore.setUaePassError('');
			userStore.setError('');

			this.setState({
				manualLinkingUIVisible: false,
				manualLinkingUINoAccountVisible: false
			});

			// Sign out user from UAE PASS if they were signed in
			if (this.state.uaePassResponse && this.state.uaePassResponse.code) {
				userStore.doUAEPASSLogout();
			}
		};

		render() {
			const { commonStore, userStore } = this.props.rootStore;
			const { principal, secret, error, uaePassError, uaePassManualLinkEligible, loading } = userStore;
			const { translateMessage, locale } = commonStore;
			const signInDisabled = !!!principal || !!!secret;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center section__title section__title--no-transform">
						{this.state.manualLinkingUIVisible ? (
							<Localized code="page.uaePassSignIn.title" />
						) : (
							<Localized code="page.signIn.title" />
						)}
					</h2>

					<div className="section__wrapper">
						<div className="signin">
							{this.state.loading ? (
								<div className="signin__loading">
									<Loader type="line-scale-pulse-out-rapid" color="#002652" />
								</div>
							) : (
								this.state.manualLinkingUIVisible ? (
									<div className="signin__uaepass-wrapper">
										{this.state.manualLinkingUIQuestionVisible && (
											<>
												<p className="signin__uaepass-question">
													<Localized code="page.uaePassSignIn.question" />
												</p>

												<div className="signin__uaepass-buttons">
													<button className="button button--blue" onClick={this.handleManualLinkingHasAccountClick}>
														<Localized code="page.uaePassSignIn.button.withAccount" />
													</button>
													<button className="button button--blue" onClick={this.handleManualLinkingNoAccountClick}>
														<Localized code="page.uaePassSignIn.button.withoutAccount" />
													</button>
												</div>

												<div className="signin__uaepass-footnotes">
													<p className="signin__uaepass-footnote">
														<Localized code="page.uaePassSignIn.text.footNote1" />
													</p>													
													<p className="signin__uaepass-footnote">
														<Localized code="page.uaePassSignIn.text.footNote2" />
													</p>
												</div>

												<p className="signin__uaepass-note">
													<Localized code="page.uaePassSignIn.text.note" />
												</p>
											</>
										)}

										{this.state.manualLinkingUINoAccountVisible && (
											<>
												<p className="signin__uaepass-no-account">
													<Localized code="page.uaePassSignIn.text.noAccount" />
												</p>

												<div className="signin__uaepass-buttons">
													<button className="button button--small button--blue" onClick={this.handleManualLinkingCloseClick}>
														<Localized code="page.uaePassSignIn.button.close" />
													</button>
												</div>
											</>
										)}

										{this.state.manualLinkingUILinkAccountVisible && (
											<>
												<p className="signin__uaepass-please-login">
													<Localized code="page.uaePassSignIn.text.loginWithFazaaCredentials" />
												</p>

												<div className="signin__form signin__form--uaepass">
													<form className="form">
														{!!error ? (
															<div className="form__message form__message--error">{error}</div>
														) : null}

														<div className="form__group">
															<label className="form__label form__label--center" htmlFor="principal">
																<Localized code="page.signIn.label.principal" />
															</label>
															<input className="form__input" type="text" id="principal" name="principal" value={principal} onChange={this.handlePrincipalChange} />
														</div>

														<div className="form__group">
															<label className="form__label form__label--center" htmlFor="secret">
																<Localized code="page.signIn.label.secret" />
															</label>
															<div className="form__input-wrap">
																{this.state.showPassword ? (
																	<FontAwesomeIcon className="form__input-wrap-icon" icon={faEyeSlash} onClick={this.handlePasswordShowToggle} />
																) : (
																	<FontAwesomeIcon className="form__input-wrap-icon" icon={faEye} onClick={this.handlePasswordShowToggle} />
																)}
																<input className="form__input" type={this.state.showPassword ? "text" : "password"} id="secret" name="secret" value={secret} onChange={this.handleSecretChange} />
															</div>
														</div>

														<div className="form__buttons">
															<button disabled={loading || signInDisabled} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={e => this.handleSignInClick(e, true)}>
																{loading ? (
																	<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
																) : null}
																<Localized code="page.uaePassSignIn.button.link" />
															</button>

															<Link to="/account/forgot" className="form__link form__link--gold form__link--underline">
																<Localized code="page.signIn.link.forgotPassword" />
															</Link>
														</div>
													</form>
												</div>

												<p className="signin__uaepass-note">
													<Localized code="page.uaePassSignIn.text.note" />
												</p>

												<p className="signin__uaepass-register">
													<Localized code="page.uaePassSignIn.text.register" __links={{
														link1: <Link className="section__link section__link--gold section__link--underline" to={'/account/signup'}></Link>
													}} />
												</p>
											</>
										)}
									</div>
								) : (
									<>
										<div className="signin__form">
											<form className="form">
												{!!error ? (
													<div className="form__message form__message--error">{error}</div>
												) : null}

												<div className="form__group">
													<label className="form__label form__label--center" htmlFor="principal">
														<Localized code="page.signIn.label.principal" />
													</label>
													<input className="form__input" type="text" id="principal" name="principal" value={principal} onChange={this.handlePrincipalChange} />
												</div>

												<div className="form__group">
													<label className="form__label form__label--center" htmlFor="secret">
														<Localized code="page.signIn.label.secret" />
													</label>
													<div className="form__input-wrap">
														{this.state.showPassword ? (
															<FontAwesomeIcon className="form__input-wrap-icon" icon={faEyeSlash} onClick={this.handlePasswordShowToggle} />
														) : (
															<FontAwesomeIcon className="form__input-wrap-icon" icon={faEye} onClick={this.handlePasswordShowToggle} />
														)}
														<input className="form__input" type={this.state.showPassword ? "text" : "password"} id="secret" name="secret" value={secret} onChange={this.handleSecretChange} />
													</div>
												</div>

												<div className="form__buttons">
													<button disabled={loading || signInDisabled} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleSignInClick}>
														{loading ? (
															<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
														) : null}
														<Localized code="page.signIn.button.signIn" />
													</button>
												</div>
											</form>

											<ul className="signin__links">
												<li className="signin__link-item">
													<Link className="button button--blue button--small signin__link" to="/account/forgot">
														<Localized code="page.signIn.link.forgotPassword" />
													</Link>
												</li>
												<li className="signin__link-item">
													<Link className="button button--blue button--small signin__link" to="/account/signup">
														<Localized code="page.signIn.link.signUp" />
													</Link>
												</li>
											</ul>
										</div>

										<div className="signin__options">
											<span className="signin__options-or">OR</span>

											{!!uaePassError ? (
												<div className="form__message form__message--error">{uaePassError}</div>
											) : null}

											<ul className="signin__links signin__links--no-margin">
												<li className="signin__link-item">
													<button className="uaepass-button uaepass-button--no-border" onClick={this.handleUAEPassLogin}>
														<img className="uaepass-button__image" src={locale === 'ar' ? imgUaePassSignInButtonAr : imgUaePassSignInButton} alt={translateMessage('page.uaepass.signInWithUAEPASS')} title={translateMessage('page.uaepass.signInWithUAEPASS')} />
													</button>
												</li>
											</ul>
										</div>
									</>
								)
							)}
						</div>
					</div>
				</section>
			);
		}

	}
));

export default SignIn;
