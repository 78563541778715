import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Localized from '../base/i18n/Localized';
import { autorun } from 'mobx';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import moment from 'moment-timezone';
import Config from '../../config/Config';
import ModalType from '../../types/ModalType';

moment.tz.setDefault('Asia/Dubai');

const Account = inject('rootStore')(observer(
	class Account extends Component {

		constructor(props) {
			super(props);

			this.state = {
				extraOffersSaved: null,
				uaePassSignIn: false
			};

			this.handleOpenFavoriteCategories = this.handleOpenFavoriteCategories.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.account.title');
			});
		}

		componentDidMount() {
			const { orderStore, userStore } = this.props.rootStore;

			orderStore.loadOrders(false);

			userStore.extraOffersSaved().then(result => {
				this.setState({
					extraOffersSaved: result
				});
			});

			// Check if user is signed in via UAE PASS
			const uaePassFlowDataJson = localStorage.getItem('uaePassFlowData');

			if (uaePassFlowDataJson) {
				const uaePassFlowData = JSON.parse(uaePassFlowDataJson);

				if (uaePassFlowData && uaePassFlowData.status) {
					this.setState({
						uaePassSignIn: true
					});
				}
			}
		}

		componentWillUnmount() {
			this.mobxAutorunDisposer();
		}

		handleOpenFavoriteCategories(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.showFavoriteCategories();
		}

		/**
		 * @deprecated
		 */
		handleUAEPassUnlink = () => {
			const { userStore, commonStore } = this.props.rootStore;
			const { translateMessage } = commonStore;

			userStore.unlinkUAEPass().then(result => {
				if (result) {
					commonStore.setModalType(ModalType.SUCCESS);
					commonStore.setModalTitle('UAE PASS');
					commonStore.setModalMessage(translateMessage('page.uaepass.unlink.success'));
				} else {
					commonStore.setModalType(ModalType.ERROR);
					commonStore.setModalTitle('UAE PASS');
					commonStore.setModalMessage(userStore.error);
				}

				commonStore.setModalVisible(true);
			});
		};

		render() {
			const { commonStore, userStore, orderStore } = this.props.rootStore;
			const { locale } = commonStore;
			const { me } = userStore;
			const level = me && me.membershipLevel && me.membershipLevel.levelName ? me.membershipLevel.levelName.split(' ')[0].toLowerCase() : undefined;
			const { loading: ordersLoading, orders } = orderStore;
			const noUpgrade = !me || !me.membershipLevel || !me.membershipLevel.levelName || me.membershipLevel.levelName === 'Platinum' || !!!me.companyCode.companyAllowSignup || me.companyCode.companyLevelId >= me.membershipLevel.id || me.companyCode.companyInitiative;

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.account.title" />
					</h2>

					<div className="section__wrapper">
						<div className="account">
							<div className="section__columns">
								<div className="section__column">
									<h3 className="section__subtitle">
										<Localized code="page.account.subtitle.dashboard" />
									</h3>

									<p className="section__text section__text--lead">
										<Localized code="page.account.text.welcome" name={locale === 'ar' ? me.fullNameAr : me.fullNameEn} />
									</p>

									<div className={`account__level account__level--${level}`}>
										<div className="account__level-badge">
											<h4 className="account__level-title">
												{me.companyCode.companyInitiative ?
													(locale === 'ar' ? me.companyCode.companyArShortName : me.companyCode.companyEnShortName) : 
													me.membershipLevel.levelName}
											</h4>

											<div className="account__level-info-wrapper">
												<p className="account__level-info">
													<strong><Localized code="page.account.text.expires" /></strong> {me.subscriptionExpireDate ? moment(me.subscriptionExpireDate).format('DD/MM/YYYY') : 'N/A'}
													<br />
													<strong><Localized code="page.account.text.membershipNo" /></strong> {me.membershipNumber}
													<br />
													<strong><Localized code="page.account.text.status" /></strong> {me.status}
												</p>

												{me.membershipLevel.id !== 4 && me.subscriptionExpireDate && moment(me.subscriptionExpireDate).isBefore() ? (
													<div className="account__level-renew">
														<Link to="/account/renew" className="button button--wide button--small button--gold">
															<Localized code="page.account.button.renew" />
														</Link>
													</div>
												) : null}

												{!noUpgrade ? (
													<div className="account__level-upgrade">
														<Link to="/account/upgrade" className="button button--wide button--small button--gold">
															<Localized code="page.account.button.upgrade" />
														</Link>
													</div>
												) : null}
											</div>
										</div>
									</div>

									<div className="account__controls">
										<Link to="/account/profile" className="account__controls-button button button--small button--gold">
											<Localized code="page.account.link.updateProfile" />
										</Link>

										{!this.state.uaePassSignIn && (
											<Link to="/account/password" className="account__controls-button button button--small button--gold">
												<Localized code="page.account.link.changePassword" />
											</Link>
										)}
									</div>

									{false && me.membershipLevel.id < 4 ? (
										<Fragment>
											<h3 className="section__subtitle" style={{ marginTop: 15 }}>
												<Localized code="page.account.subtitle.prepaidCard" />
											</h3>

											<div className="account__controls" style={{ marginBottom: 30 }}>
												<Link to="/account/prepaid-card" className="button button--small button--gold">
													<Localized code="page.account.button.prepaidCard" />
												</Link>
											</div>
										</Fragment>
									) : null}

									{me.uaePassId && (
										false && (
											<>
												<p className="section__text">
													<Localized code="page.account.text.uaePassLinked" />
												</p>

												<div className="account__controls">
													<button className="account__controls-button button button--small button--gold" onClick={this.handleUAEPassUnlink}>
														<Localized code="page.account.button.uaePassUnlink" />
													</button>
												</div>
											</>
										)
									)}

									<h3 className="section__subtitle">
										<Localized code="page.account.subtitle.favoriteCategories" />
									</h3>

									<p className="section__text">
										<Localized code="page.account.text.favoriteCategories" />
									</p>

									<div className="account__controls">
										<button className="account__controls-button button button--small button--gold" onClick={this.handleOpenFavoriteCategories}>
											<Localized code="page.account.button.favoriteCategories" />
										</button>
									</div>
								</div>

								<div className="section__column">
									{!Config.HIDE_DYNAMIC_OFFERS && (
										<Fragment>
											<h3 className="section__subtitle">
												<Localized code="page.account.subtitle.extraOffersSaved" />
											</h3>

											{this.state.extraOffersSaved ? (
												<Fragment>
													<div className="account__extra-offers">
														{false && (
															<div className="account__extra-offers-stats">
																<div className="account__extra-offers-stats-column">
																	<div className="account__extra-offers-stats-title">
																		<Localized code="page.account.text.extraOffersTransactionAmount" />
																	</div>

																	<div className="account__extra-offers-stats-value">{this.state.extraOffersSaved.transactionAmount} AED</div>
																</div>

																<div className="account__extra-offers-stats-column">
																	<div className="account__extra-offers-stats-title">
																		<Localized code="page.account.text.extraOffersDiscountAmount" />
																	</div>

																	<div className="account__extra-offers-stats-value">{this.state.extraOffersSaved.discountAmount} AED</div>
																</div>

																<div className="account__extra-offers-stats-column">
																	<div className="account__extra-offers-stats-title">
																		<Localized code="page.account.text.extraOffersSavedPercent" />
																	</div>

																	<div className="account__extra-offers-stats-value">{this.state.extraOffersSaved.transactionAmount === 0 ? '0' : (this.state.extraOffersSaved.discountAmount * 100 / this.state.extraOffersSaved.transactionAmount).toFixed(0)}%</div>
																</div>
															</div>
														)}

														<div className="account__controls">
															<Link className="account__controls-button button button--small button--gold" to="/account/transactions">
																<Localized code="page.account.button.extraOffersTransactions" />
															</Link>
														</div>
													</div>
												</Fragment>
											) : (
												<p className="section__text">
													<Localized code="page.account.text.noExtraOffersSavedData" __links={{
														link1: <Link className="section__link" to={`/search?language=${locale}&types=EXTRA_OFFERS`}></Link>
													}} />
												</p>
											)}
										</Fragment>
									)}

									<h3 className="section__subtitle">
										<Localized code="page.account.subtitle.latestOrders" />
									</h3>

									{!ordersLoading ? (
										orders.length > 0 ? (
											<div className="account__orders">
												<div className="account__orders-header">
													<div className="account__orders-header-column account__orders-header-column--order-id">
														<Localized code="page.account.text.orderId" />
													</div>
													<div className="account__orders-header-column account__orders-header-column--amount">
														<Localized code="page.account.text.orderAmount" />
													</div>
													<div className="account__orders-header-column account__orders-header-column--status">
														<Localized code="page.account.text.orderStatus" />
													</div>
												</div>

												<div className="account__orders-body">
													{orders.map(order => (
														<Link key={order.orderId} className="account__orders-row" to={`/account/orders/view/${order.orderId}`}>
															<div className="account__orders-body-column account__orders-body-column--order-id">
																{order.orderId}
																<br />
																<small>{moment(order.createdAt).format('DD/MM/YY')}</small>
															</div>
															<div className="account__orders-body-column account__orders-body-column--amount">
																{order.total} AED
															</div>
															<div className="account__orders-body-column account__orders-body-column--status">
																{order.status}
																<br />
																<small title="Last update">{moment(order.statusChanged).format('DD/MM/YY @ HH:MM:SS')}</small>
															</div>
														</Link>
													))}
												</div>
											</div>
										) : (
											<p className="section__text">
												<Localized code="page.account.text.noOrders" __links={{
													link1: <Link className="section__link" to={`/search?language=${locale}&types=VOUCHER`}></Link>
												}} />
											</p>
										)
									) : (
										<div className="account__orders-loading">
											<Loader type="line-scale-pulse-out-rapid" color="#002652" />
										</div>
									)}

									<div className="account__controls">
										<Link to="/account/orders" className="account__controls-button button button--small button--gold">
											<Localized code="page.account.link.orders" />
										</Link>

										<Link to="/account/orders/archive" className="account__controls-button button button--small button--gold">
											<Localized code="page.account.link.ordersArchive" />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			);
		}

	}
));

export default Account;
