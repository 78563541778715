import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import FilterMultiSelect from '../base/filter/FilterMultiSelect';
import SearchType from '../../types/SearchType';
import Qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { GoogleApiWrapper, Map, Marker, Circle } from 'google-maps-react';
import Config from '../../config/Config';
import markerStarBlue from '../../assets/img/marker-star-blue.png';
import myIcon from '../../assets/img/marker-self-yellow.png';
import { autorun, runInAction } from 'mobx';
import Loader from 'react-loaders';
import Localized from '../base/i18n/Localized';
import Autocomplete from 'react-google-autocomplete';

const resultIcon = {
	url: markerStarBlue
};

const MapSearch = inject('rootStore')(withRouter(observer(
	class MapSearch extends Component {

		constructor(props) {
			super(props);

			const { position } = props.rootStore.userStore;

			this.mounted = false;
			this.moved = false;

			this.state = {
				// user position
				position: {
					lat: 0,
					lng: 0
				},
				mapCenterPosition: {
					lat: position ? position.latitude : 25.276987,
					lng: position ? position.longitude : 55.296249
				},
				distanceInMeters: 5000,
				currentItem: null,
				popupOpen: false,
				filtersOpen: false
			};

			this.handleAfterOpen = this.handleAfterOpen.bind(this);
			this.handleAfterClose = this.handleAfterClose.bind(this);
			this.handleCloseMapSearch = this.handleCloseMapSearch.bind(this);
			this.handleQueryChange = this.handleQueryChange.bind(this);
			this.handleTypesChange = this.handleTypesChange.bind(this);
			this.handleCategoriesChange = this.handleCategoriesChange.bind(this);
			this.handleLocationsChange = this.handleLocationsChange.bind(this);
			this.handleDistanceChange = this.handleDistanceChange.bind(this);
			this.handleMapIdle = this.handleMapIdle.bind(this);
			this.triggerSearch = this.triggerSearch.bind(this);
			this.handleMarkerClick = this.handleMarkerClick.bind(this);
			this.handleClosePopup = this.handleClosePopup.bind(this);
			this.handleViewResult = this.handleViewResult.bind(this);
			this.handleToggleFilters = this.handleToggleFilters.bind(this);
			this.triggerQuerySearch = debounce(this.triggerQuerySearch.bind(this), 1000);
			this.handlePlaceSelected = this.handlePlaceSelected.bind(this);

			autorun(() => {
				const { userStore, searchStore } = this.props.rootStore;
				const { position } = userStore;
				const { distance, mapCenterLatitude, mapCenterLongitude } = searchStore;

				if (position && (position.latitude !== this.state.position.lat || position.longitude !== this.state.position.lng)) {
					if (this.mounted) {
						this.setState({
							position: {
								lat: position.latitude,
								lng: position.longitude
							}
						});
					}
				}

				if (this.mounted) {
					if (this.state.distanceInMeters !== (distance ? distance : 5) * 1000) {
						this.setState({
							distanceInMeters: (distance ? distance : 5) * 1000
						});
					}

					if (this.moved && (this.state.mapCenterPosition.lat !== mapCenterLatitude || this.state.mapCenterPosition.lng !== mapCenterLongitude)) {
						this.setState({
							mapCenterPosition: {
								lat: mapCenterLatitude,
								lng: mapCenterLongitude
							}
						});
					}
				}
			});
		}

		componentDidMount() {
			this.mounted = true;
		}

		componentWillUnmount() {
			this.mounted = false;

			delete window.google;
			window._scriptMap.delete('google');

			document.querySelectorAll('script').forEach(script => {
				if (script.src.startsWith('https://maps.googleapis.com/maps/api/js')) {
					script.parentNode.removeChild(script);
				}
			});
		}

		handleAfterOpen() {
			document.querySelector('html').style.overflow = 'hidden';
			document.querySelector('body').style.overflow = 'hidden';

			this.handleDistanceChange(5);
		}

		handleAfterClose() {
			document.querySelector('html').style.overflow = '';
			document.querySelector('body').style.overflow = '';

			this.handleDistanceChange(undefined);
		}

		handleCloseMapSearch() {
			const { searchStore } = this.props.rootStore;

			searchStore.setMapSearchOpen(false);
		}

		handleQueryChange(e) {
			const { searchStore } = this.props.rootStore;

			searchStore.setQuery(e.target.value);

			this.triggerQuerySearch();
		}

		triggerQuerySearch() {
			const { search } = this.props.location;
			const { searchStore: { query : q} } = this.props.rootStore;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			if (q) {
				params.q = q;
			} else {
				delete params.q;
			}

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		handleTypesChange(values) {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.types = values;

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		handleCategoriesChange(values) {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.categories = values;

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		handleLocationsChange(values) {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.cities = values;

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		handleDistanceChange(value) {
			const { commonStore, searchStore } = this.props.rootStore;
			const { search } = this.props.location;
			const { locale } = commonStore;
			const { mapSearchClosing } = searchStore;

			if (!mapSearchClosing) {
				const params = Qs.parse(search, {
					ignoreQueryPrefix: true
				});

				params.distanceInKm = value;
				params.language = locale;

				const query = Qs.stringify(params, {
					arrayFormat: 'repeat'
				});

				this.props.history.push(`/search?${query}`);
			}
		}

		handleMapIdle(mapProps, map, event) {
			const { searchStore } = this.props.rootStore;
			const { mapCenterLatitude, mapCenterLongitude } = searchStore;

			if (Number(map.getCenter().lat().toFixed(6)) !== Number(mapCenterLatitude.toFixed(6)) || Number(map.getCenter().lng().toFixed(6)) !== Number(mapCenterLongitude.toFixed(6))) {
				runInAction(() => {
					searchStore.setMapCenterLatitude(Number(map.getCenter().lat().toFixed(6)));
					searchStore.setMapCenterLongitude(Number(map.getCenter().lng().toFixed(6)));
				});

				this.triggerSearch();

				this.moved = true;
			}
		}

		triggerSearch() {
			const { search } = this.props.location;
			const { searchStore } = this.props.rootStore;
			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			searchStore.doSearch(params);
		}

		handleMarkerClick(props, marker, event) {
			this.setState({
				currentItem: props.item,
				popupOpen: true
			});
		}

		handleClosePopup() {
			this.setState({
				popupOpen: false
			});
		}

		handleViewResult() {
			const { currentItem } = this.state;

			if (currentItem.type === 'SERVICE') {
				if (currentItem.serviceSubType === 'CARS') {
					window.open(`/services/view/long-term-car-lease/${currentItem.slug}`);
				} else {
					if (parseInt(currentItem.serviceType) === 2) {
						// Use localized or fallback to legacy service link
						const serviceLink = currentItem.localizedServiceLink ? currentItem.localizedServiceLink : currentItem.serviceLink;

						if (serviceLink.startsWith('http')) {
							window.open(serviceLink);
						} else {
							// Relative
							window.open(`/${serviceLink}`);
						}
					} else {
						window.open(`/services/view/${currentItem.slug}`);
					}
				}
			} else {
				window.open(`/${currentItem.type.toLowerCase()}s/view/${currentItem.slug}`);
			}
		}

		handleToggleFilters() {
			this.setState(state => {
				return {
					filtersOpen: !state.filtersOpen
				}
			});
		}

		handlePlaceSelected(place) {
			const { searchStore } = this.props.rootStore;

			if (place && place.geometry && place.geometry.location) {
				searchStore.setMapCenterLatitude(Number(place.geometry.location.lat().toFixed(6)));
				searchStore.setMapCenterLongitude(Number(place.geometry.location.lng().toFixed(6)));

				this.triggerSearch();

				this.moved = true;
			}
		}

		render() {
			const { google } = this.props;
			const { commonStore, searchStore } = this.props.rootStore;
			const { locale, translateMessage } = commonStore;
			const { mapSearchOpen, query, locationResults } = searchStore;
			const types = SearchType.map(type => ({
				value: type.value,
				label: commonStore.translateMessage('page.search.label.' + type.name.toLowerCase())
			}));
			const categories = searchStore.availableCategories.map(category => ({
				value: category.localData[locale].name,
				label: category.localData[locale].name
			}));
			const locations = searchStore.locations.map(location => ({
				heading: location.heading,
				label: location.heading ? location.country.localData[locale].name : location.city.localData[locale].name,
				value: location.heading ? location.country.internalName : location.city.internalName
			}));
			const distances = [{
				value: 5, label: commonStore.translateMessage('page.search.label.5kmRadius')
			}, {
				value: 10, label: commonStore.translateMessage('page.search.label.10kmRadius')
			}, {
				value: 25, label: commonStore.translateMessage('page.search.label.25kmRadius')
			}, {
				value: 50, label: commonStore.translateMessage('page.search.label.50kmRadius')
			}];

			return (
				<Modal
					isOpen={mapSearchOpen}
					overlayClassName="map-search"
					className="map-search__content"
					ariaHideApp={false}
					onAfterOpen={this.handleAfterOpen}
					onAfterClose={this.handleAfterClose}
				>
					<div className="map-search__header">
						<div className="map-search__filter map-search__filter--query">
							<input type="text" className="map-search__query" placeholder={translateMessage('header.search.placeholder')} onChange={e => { e.persist(); this.handleQueryChange(e)}} value={query} />
							<FontAwesomeIcon className="map-search__query-icon" icon={faSearch} />
						</div>

						<div className={"map-search__filters" + (this.state.filtersOpen ? " map-search__filters--open" : "")}>
							<button className="map-search__filters-close" onClick={this.handleToggleFilters}>&times;</button>

							<h5 className="map-search__filters-title">
								<Localized code="page.search.title.filters" />
							</h5>

							<div className="map-search__filter">
								<FilterMultiSelect items={types} selected={searchStore.types} onChange={this.handleTypesChange} placeholder={commonStore.translateMessage('page.search.label.allTypes')} />
							</div>

							<div className="map-search__filter">
								<FilterMultiSelect items={categories} selected={searchStore.categories} onChange={this.handleCategoriesChange} placeholder={commonStore.translateMessage('page.search.label.allCategories')} />
							</div>

							<div className="map-search__filter">
								<FilterMultiSelect items={locations} selected={searchStore.cities} onChange={this.handleLocationsChange} placeholder={commonStore.translateMessage('page.search.label.cities')} />
							</div>

							<div className="map-search__filter">
								<FilterMultiSelect single items={distances} selected={searchStore.distance} onChange={this.handleDistanceChange} placeholder={commonStore.translateMessage('page.search.label.anyDistance')} />
							</div>
						</div>

						<div className="map-search__filter-button">
							<button onClick={this.handleToggleFilters}>
								<Localized code="page.search.button.filters" />
							</button>
						</div>

						<div className="map-search__close">
							<button onClick={this.handleCloseMapSearch}>&times;</button>
						</div>
					</div>

					<div className="map-search__body">
						{!this.state.filtersOpen ? (
							<div className="map-search__autocomplete">
								<Autocomplete
									className="map-search__autocomplete-input"
									onPlaceSelected={this.handlePlaceSelected}
									types={['geocode']}
									placeholder={commonStore.translateMessage('page.search.placeholder.searchByLocation')}
								/>
								<FontAwesomeIcon className="map-search__autocomplete-icon" icon={faMapPin} />
							</div>
						) : null}

						<div className="map-search__map">
							<Map
								google={google}
								zoom={Config.GOOGLE_MAPS_DEFAULT_ZOOM}
								//initialCenter={position ? { lat: position.latitude, lng: position.longitude } : { lat: mapCenterLatitude, lng: mapCenterLongitude }}
								initialCenter={this.state.mapCenterPosition}
								onIdle={this.handleMapIdle}
								fullscreenControl={false}
								mapTypeControl={false}
								streetViewControl={false}
								center={this.state.mapCenterPosition}
							>
								<Marker
									position={this.state.position}
									title={commonStore.translateMessage('page.search.text.self')}
									icon={myIcon}
								/>
								{locationResults.map(item => (
									<Marker
										key={item.hash} 
										position={item.location}
										icon={resultIcon}
										item={item}
										title={item.combinedTitle}
										onClick={this.handleMarkerClick}
									/>
								))}
								<Circle
									radius={this.state.distanceInMeters}
									center={this.state.mapCenterPosition}
									strokeColor='transparent'
									strokeOpacity={0}
									strokeWeight={0}
									fillColor='#002652'
									fillOpacity={0.1}
								/>
							</Map>
						</div>
					</div>

					<Modal
						isOpen={this.state.popupOpen}
						overlayClassName="modal modal--search-result"
						className="modal__content modal__content--search-result"
						ariaHideApp={false}
						shouldCloseOnEsc={true}
						shouldCloseOnOverlayClick={true}
						onRequestClose={() => this.setState({ popupOpen: false })}
					>
						{this.state.currentItem ? (
							<Fragment>
								<button className="modal__close" onClick={this.handleClosePopup}>&times;</button>

								<div className="modal__image-wrapper">
									<img className="modal__image" src={`${Config.UPLOADS_ENDPOINT}${this.state.currentItem.imageUri}`} alt={this.state.currentItem.title} />

									<div className="modal__image-tag">{commonStore.translateMessage('page.search.label.' + this.state.currentItem.type.toLowerCase())}</div>
								</div>

								<div className="modal__search-result">
									{!!this.state.currentItem.partnerName ? (
										<h3 className="modal__search-result-partner">{this.state.currentItem.partnerName}</h3>
									) : null}
									
									<p className="modal__search-result-title">{this.state.currentItem.title}</p>
								</div>

								<div className="modal__buttons modal__buttons--wide">
									<button className="button button--gold button--small" onClick={this.handleViewResult}>
										<Localized code="page.search.viewResult" />
									</button>
								</div>
							</Fragment>
						) : (
							<div className="modal__loading">
								<Loader type="line-scale-pulse-out-rapid" color="#002652" />
							</div>
						)}
					</Modal>
				</Modal>
			);
		}

	}
)));

export default GoogleApiWrapper((props) => ({
	apiKey: (Config.GOOGLE_MAPS_API_KEY),
	libraries: ['places'],
	language: props.locale
}))(MapSearch);
